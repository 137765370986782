var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import '@polymer/paper-button/paper-button';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
let BadphraimLegacyButton = class BadphraimLegacyButton extends LitElement {
    static get styles() {
        return css `
            :host([width-full]) paper-button {
                margin: 10px 0;
                width: 100%;
            }

            :host paper-button {
                margin: var(--cerum-legacy-button-margin, 0 0.29em);
                width: var(--cerum-legacy-button-width);
            }

            paper-button {
                min-width: var(--cerum-legacy-button-min-width, 5.14em);
                height: var(--cerum-legacy-button-height);
                border-radius: var(--cerum-legacy-button-border-radius, 3px);
                border: var(--cerum-legacy-button-border, 0);
            }

            paper-button.small {
                min-width: var(--cerum-legacy-button-min-width, 2em);
            }

            iron-icon {
                height: var(--iron-icon-height, 12);
                width: var(--iron-icon-width, 12px);
                margin: 0 10px;
            }

            .icon {
                width: var(--icon-width, 10px);
                min-height: var(--icon-min-height, 18px);
            }

            paper-button {
                background-color: #ccc !important;
                color: black;
                font-size: var(--text-2xs);
                font-weight: var(--font-semibold);
                letter-spacing: 0.4px;
            }

            paper-button iron-icon {
                stroke: black;
            }

            paper-button.neutral {
                background-color: transparent !important;
                color: black;
            }

            paper-button.neutral iron-icon {
                stroke: black;
            }

            paper-button.primary {
                background-color: hsl(211.2, 13%, 90%) !important;
                color: hsl(211.2, 13%, 20%);
                border-color: hsl(211.2, 13%, 20%);
            }

            paper-button.primary-inverted,
            paper-button.primary:hover {
                background-color: hsl(211.2, 13%, 60%) !important;
            }

            paper-button.primary iron-icon {
                stroke: white;
            }

            paper-button.info {
                background-color: hsl(210, 60%, 90%) !important;
                color: hsl(210, 75%, 16%);
                border-color: hsl(210, 75%, 16%);
            }

            paper-button.info-inverted,
            paper-button.info:hover {
                background-color: var(--cerum-blue) !important;
            }

            paper-button.info iron-icon {
                stroke: white;
            }

            paper-button.success {
                background-color: hsl(159, 60%, 90%) !important;
                color: hsl(159, 75%, 16%);
                border-color: hsl(159, 75%, 16%);
            }

            paper-button.success-inverted,
            paper-button.success:hover {
                background-color: var(--cerum-green) !important;
            }

            paper-button.success iron-icon {
                stroke: white;
            }

            paper-button.warning {
                background-color: hsl(28, 80%, 93%) !important;
                color: hsl(28, 74%, 24%);
                border-color: hsl(28, 74%, 24%);
            }

            paper-button.warning-inverted,
            paper-button.warning:hover {
                background-color: var(--cerum-orange) !important;
            }

            paper-button.warning iron-icon {
                stroke: white;
            }

            paper-button.danger {
                background-color: hsl(3, 100%, 96%) !important;
                color: hsl(360, 76%, 20%);
                border-color: hsl(360, 76%, 20%);
            }

            paper-button.danger-inverted,
            paper-button.danger:hover {
                background-color: var(--cerum-red) !important;
            }

            paper-button.danger iron-icon {
                stroke: white;
            }

            paper-button[disabled] {
                opacity: 0.3;
            }

            .loading {
                margin-left: 6px;
                margin-top: 2px;
            }
        `;
    }
    constructor() {
        super();
        this.loading = false;
        this.raised = false;
        this.toggles = false;
        this.isMouseOverActive = false;
        this._boundOnMouseOver = this._onMouseOver.bind(this);
        this._boundOnMouseOut = this._onMouseOut.bind(this);
    }
    /** @override */ connectedCallback() {
        super.connectedCallback();
        if (this.hovertype && this.hovername) {
            this.addEventListener('mouseover', this._boundOnMouseOver);
            this.addEventListener('mouseout', this._boundOnMouseOut);
        }
    }
    /** @override */ disconnectedCallback() {
        super.disconnectedCallback();
        if (this.hovertype && this.hovername) {
            this.removeEventListener('mouseover', this._boundOnMouseOver);
            this.removeEventListener('mouseout', this._boundOnMouseOut);
        }
    }
    /** @override */ render() {
        return html `
            <paper-button
                class="${this._getClassName(this.isMouseOverActive, this.loading)}"
                ?disabled="${this._isDisabled(this.loading, this.disabled)}"
                ?raised="${this.raised}"
                ?toggles="${this.toggles}"
                @click="${this._buttonClicked}"
            >
                <slot style="margin-right: 6px" name="icon"></slot>

                ${this.icon ? html ` <iron-icon icon="${this.icon}"></iron-icon> ` : html ``}
                ${this.iconSrc ? html ` <img class="icon" src="${this.iconSrc}" />` : html ``}
                ${this._getName(this.isMouseOverActive, this.loading)}
                ${this.loading ? html ` <img class="loading" src="images/ajax-loader.gif" /> ` : html ``}
            </paper-button>
        `;
    }
    setLoading(loading) {
        this.loading = loading;
        this.disabled = loading;
    }
    _buttonClicked(_event) {
        if (this.disabled) {
            return;
        }
        const jsAction = this.action;
        if (jsAction) {
            eval('!function(event) {' + jsAction + '}(arguments[0])');
        }
        else if (this.url) {
            const jsUrl = this.url.match(/^javascript:(.*)$/s);
            if (jsUrl) {
                eval(jsUrl[1]);
            }
            else {
                this._openUrl(this.url, this.target);
            }
        }
        else {
            // console.warn(this.nodeName + ' har ingen action', this);
        }
    }
    _getClassName(isMouseOverActive, loading) {
        if (loading) {
            return (this.hovertype ? this.hovertype : this.type) || '';
        }
        if (this.hovertype) {
            return (isMouseOverActive ? this.hovertype : this.type) || '';
        }
        return this.type || '';
    }
    _getName(isMouseOverActive, loading) {
        if (loading) {
            return this.hovername ? this.hovername : this.name;
        }
        if (this.hovername) {
            return isMouseOverActive ? this.hovername : this.name;
        }
        return this.name;
    }
    _isDisabled(loading, disabled) {
        this.disabled = loading || disabled;
        return loading || disabled ? true : false;
    }
    _openUrl(url, target) {
        if (target) {
            window.open(url, target);
        }
        else {
            document.location.href = url;
        }
    }
    _onMouseOver(_event) {
        this.isMouseOverActive = true;
    }
    _onMouseOut(_event) {
        this.isMouseOverActive = false;
    }
};
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "action", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BadphraimLegacyButton.prototype, "disabled", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "hovername", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "hovertype", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "icon", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "iconSrc", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BadphraimLegacyButton.prototype, "loading", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "name", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BadphraimLegacyButton.prototype, "raised", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "target", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BadphraimLegacyButton.prototype, "toggles", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "type", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BadphraimLegacyButton.prototype, "url", void 0);
__decorate([
    property({ type: Boolean, attribute: 'width-full' }),
    __metadata("design:type", Boolean)
], BadphraimLegacyButton.prototype, "widthFull", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BadphraimLegacyButton.prototype, "isMouseOverActive", void 0);
BadphraimLegacyButton = __decorate([
    customElement('badphraim-legacy-button'),
    __metadata("design:paramtypes", [])
], BadphraimLegacyButton);
export { BadphraimLegacyButton };
