var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
let SigningFooter = class SigningFooter extends LitElement {
    static get is() {
        return 'signing-footer';
    }
    /** @override */ render() {
        return html `
            <div
                class="flex w-full flex-col lg:flex-row justify-center lg:justify-between max-w-2xl ml-auto mr-auto p-8"
            >
                <div class="flex w-full flex-row justify-center lg:justify-start">
                    &copy;&nbsp;
                    <a
                        href="http://cerum.no/"
                        class="no-underline text-black font-bold"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Cerum AS
                    </a>
                    <div class="hidden lg:block">&mdash; Org. nummer 922 712 727</div>
                </div>
                <div class="flex w-full flex-col lg:flex-row justify-center lg:justify-end">
                    <a
                        class="lg:mx-2 no-underline text-black font-bold text-center"
                        href="https://cerum.no/#/contact"
                        target="_blank"
                    >
                        Kontakt
                    </a>
                    <a
                        class="cursor-pointer text-black font-bold text-center
                               lg:mx-2 no-underline"
                        href="documents/bruksvilkar.pdf"
                        target="_blank"
                    >
                        Bruksvilkår
                    </a>
                    <a
                        class="lg:mx-2 no-underline text-black font-bold text-center"
                        href="documents/personvernerklaering.pdf"
                        target="_blank"
                    >
                        Personvernerklæring
                    </a>
                </div>
            </div>
        `;
    }
    createRenderRoot() {
        return this;
    }
};
SigningFooter._deps = [];
SigningFooter = __decorate([
    customElement(SigningFooter.is)
], SigningFooter);
export { SigningFooter };
