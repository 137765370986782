var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import '@polymer/paper-button/paper-button';
import { BadphraimLegacyButton } from 'badphraim/ui/BadphraimLegacyButton';
import { customElement } from 'lit/decorators.js';
let CerumLegacyButton = class CerumLegacyButton extends BadphraimLegacyButton {
    /** @override */ _openUrl(url, target) {
        if ('cerum' in window) {
            window.cerum.top().navigator.gotoLegacyUrl(url, target);
        }
    }
};
CerumLegacyButton = __decorate([
    customElement('cerum-legacy-button')
], CerumLegacyButton);
export { CerumLegacyButton };
