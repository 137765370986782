var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class SigningCanvasController {
    constructor(host, canvas) {
        this.ongoingTouches = [];
        this.xCord = 0;
        this.yCord = 0;
        this.offsetX = 0;
        this.offsetY = 0;
        this.isDrawing = false;
        this.lineWidth = 3;
        this.lineColor = 'black';
        this.strokes = [];
        this.currentStroke = [];
        this.originalOrientation = window.screen.orientation.angle;
        this.originalWidth = 0;
        this.showHelperText = true;
        this.isDownscaled = true;
        (this.host = host).addController(this);
        this.canvas = canvas;
        this._startup();
    }
    _startup() {
        var _a, _b, _c, _d, _e, _f;
        const context = this._getCanvasContext();
        if (context) {
            if (this._isMobileDevice()) {
                const toolbarHeight = (_c = (_b = (_a = this.host.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.toolbar-top')) === null || _b === void 0 ? void 0 : _b.clientHeight) !== null && _c !== void 0 ? _c : 0;
                const footerHeight = (_f = (_e = (_d = this.host.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('.signing-footer')) === null || _e === void 0 ? void 0 : _e.clientHeight) !== null && _f !== void 0 ? _f : 0;
                if (!this.onResizeCallback) {
                    this.onResizeCallback = () => {
                        this._resizeThrottler(toolbarHeight, footerHeight);
                    };
                    window.addEventListener('resize', this.onResizeCallback);
                }
                const { innerHeight, innerWidth } = window;
                this.canvas.width = innerWidth - 50;
                this.canvas.height = innerHeight - toolbarHeight - footerHeight - 30;
            }
            else {
                this.canvas.width = 1200;
                this.canvas.height = 600;
            }
            this.originalWidth = this.canvas.width;
            if (!this.strokes.length && this.showHelperText) {
                this._drawHelperText(context);
            }
        }
        this.canvas.addEventListener('touchstart', (event) => this._onTouchStart(event));
        this.canvas.addEventListener('touchend', () => this._ontouchEnd());
        this.canvas.addEventListener('touchcancel', (event) => this._onTouchCancel(event));
        this.canvas.addEventListener('touchmove', (event) => this._onTouchMove(event));
        this.canvas.addEventListener('mousedown', (event) => this._onMouseDown(event));
        this.canvas.addEventListener('mousemove', (event) => this._onMouseMove(event));
        this.canvas.addEventListener('mouseup', (event) => this._onMouseUp(event));
        this.host.requestUpdate();
    }
    _drawHelperText(context) {
        context.font = '30px Arial';
        context.textAlign = 'center';
        context.fillStyle = 'grey';
        context.fillText('Signer her', this.canvas.width / 2, this.canvas.height / 2);
    }
    removeHelperText() {
        this.clearCanvas();
    }
    _resizeThrottler(toolbarHeight, footerHeight) {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(() => {
            this.resizeTimeout = undefined;
            this._onResize(toolbarHeight, footerHeight);
        }, 100);
    }
    _onResize(toolbarHeight, footerHeight) {
        const { innerWidth, innerHeight } = window;
        const padding = 30;
        const shouldDownscale = screen.orientation.angle !== this.originalOrientation;
        this.canvas.width = innerWidth - padding;
        this.canvas.height = innerHeight - padding - footerHeight - toolbarHeight;
        this.redrawSignature(shouldDownscale);
    }
    redrawSignature(shouldDownscale) {
        return __awaiter(this, void 0, void 0, function* () {
            const context = this._getCanvasContext();
            if (!context) {
                return;
            }
            if (!this.strokes.length && this.showHelperText) {
                this._drawHelperText(context);
                return;
            }
            const strokes = structuredClone(this.strokes);
            if (shouldDownscale) {
                const scalingFactor = this._getScalingFactor(this.originalWidth);
                strokes.forEach((stroke) => {
                    stroke.points.map((point) => {
                        point.x *= scalingFactor;
                        point.y *= scalingFactor;
                    });
                    return stroke;
                });
                this.isDownscaled = true;
            }
            strokes.forEach((stroke) => {
                if (stroke.points.length > 0 && stroke.points.at(0)) {
                    const { x: xCord, y: yCord } = stroke.points[0];
                    context.beginPath();
                    context.moveTo(xCord, yCord);
                    stroke.points.forEach((point) => {
                        const { x: xCord, y: yCord } = point;
                        context.lineTo(xCord, yCord);
                    });
                    context.lineWidth = this.lineWidth;
                    context.strokeStyle = this.lineColor;
                    context.lineJoin = 'round';
                    context.stroke();
                    context.closePath();
                }
            });
        });
    }
    _getScalingFactor(originalWidth) {
        let scalingFactor = this.canvas.width / originalWidth;
        if (this.isDownscaled && this.originalOrientation === 0) {
            scalingFactor = 1;
        }
        return scalingFactor;
    }
    _isMobileDevice() {
        return 'ontouchstart' in window;
    }
    isSignatureInvalid() {
        return this._isCanvasEmpty();
    }
    _onMouseMove(event) {
        const context = this._getCanvasContext();
        if (!context) {
            return;
        }
        if (this.isDrawing) {
            this._drawLine(context, this.xCord, this.yCord, event.offsetX, event.offsetY);
            this.xCord = event.offsetX;
            this.yCord = event.offsetY;
        }
    }
    _onMouseDown(event) {
        this.xCord = event.offsetX;
        this.yCord = event.offsetY;
        this.isDrawing = true;
    }
    _onMouseUp(event) {
        const context = this._getCanvasContext();
        if (!context) {
            return;
        }
        if (this.isDrawing) {
            this._drawLine(context, this.xCord, this.yCord, event.offsetX, event.offsetY);
            this.xCord = 0;
            this.yCord = 0;
            this.isDrawing = false;
        }
    }
    _onTouchStart(event) {
        event.preventDefault();
        const touches = event.changedTouches;
        const { left, top } = this.canvas.getBoundingClientRect();
        this.offsetX = left;
        this.offsetY = top;
        for (let i = 0; i < touches.length; i++) {
            const item = touches.item(i);
            if (item) {
                this.ongoingTouches.push(item);
            }
        }
    }
    _onTouchMove(event) {
        event.preventDefault();
        const context = this._getCanvasContext();
        if (!context) {
            return;
        }
        const touches = event.changedTouches;
        for (let i = 0; i < touches.length; i++) {
            const touch = touches[i];
            const xCord = touch.clientX - this.offsetX;
            const yCord = touch.clientY - this.offsetY;
            context.beginPath();
            if (this.currentStroke.length > 0) {
                const xCord = this.currentStroke[this.currentStroke.length - 1].x;
                const yCord = this.currentStroke[this.currentStroke.length - 1].y;
                context.moveTo(xCord, yCord);
            }
            else {
                context.moveTo(xCord, yCord);
            }
            context.lineTo(xCord, yCord);
            context.lineWidth = this.lineWidth;
            context.strokeStyle = this.lineColor;
            context.lineJoin = 'round';
            context.closePath();
            context.stroke();
            this.currentStroke.push({ x: xCord, y: yCord });
        }
    }
    _ontouchEnd() {
        this.strokes.push({ points: [...this.currentStroke] });
        this.currentStroke = [];
    }
    _onTouchCancel(event) {
        event.preventDefault();
        const touches = event.changedTouches;
        for (let i = 0; i < touches.length; i++) {
            const touchIdentifier = this._ongoingTouchIndexById(touches[i].identifier);
            this.ongoingTouches.splice(touchIdentifier, 1);
        }
    }
    _ongoingTouchIndexById(idToFind) {
        for (let i = 0; i < this.ongoingTouches.length; i++) {
            const id = this.ongoingTouches[i].identifier;
            if (id === idToFind) {
                return i;
            }
        }
        return -1;
    }
    _drawLine(context, x1, y1, x2, y2) {
        context.beginPath();
        context.imageSmoothingQuality = 'high';
        context.strokeStyle = this.lineColor;
        context.lineWidth = this.lineWidth;
        context.lineJoin = 'round';
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.closePath();
        context.stroke();
    }
    _isCanvasEmpty() {
        const context = this._getCanvasContext();
        if (!context) {
            return true;
        }
        const pixelBuffer = new Uint32Array(context.getImageData(0, 0, this.canvas.width, this.canvas.height).data.buffer);
        return !pixelBuffer.some((color) => color !== 0);
    }
    clearCanvas() {
        var _a;
        const context = this._getCanvasContext();
        if (!context) {
            return;
        }
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        this.strokes = [];
        const checkbox = (_a = this.host.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('vaadin-checkbox');
        if (checkbox) {
            checkbox.checked = false;
        }
        this.host.requestUpdate();
    }
    _getCanvasContext() {
        return this.canvas.getContext('2d');
    }
    hostConnected() {
        //
    }
    hostDisconnected() {
        //
    }
}
