/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { PendingSignatureData } from '../../../schemas/PendingSignatureData';
import { SigningEntryData } from '../../../schemas/SigningEntryData';
export class ChecklistSigningAPI extends APIRestClient {
    /**
     * Remove signature
     * Implementert hovedsakelig i *ewRestRequestExtChecklistSigning*::*removeSignature*
     * bpRestRequestJsonResponse<bool>
     */
    static deleteV1ChecklistsExtsigningRejectUsingSigninghash(signingHash) {
        return this._delete('/v1/checklists/extsigning/{signingHash}/reject', { signingHash }, null, null, APIRestClient.Native.boolean(), [], 'json');
    }
    /**
     * Read the signature
     * Implementert hovedsakelig i *ewRestRequestExtChecklistSigning*::*readSignature*
     * Reads the signature.
     */
    static getV1ChecklistsExtsigningSignatureUsingSigninghash(signingHash) {
        return this._get('/v1/checklists/extsigning/{signingHash}/signature', { signingHash }, null, null, APIRestClient.Native.Blob(), [], 'blob');
    }
    /**
     * Reads info about the signing request
     * Implementert hovedsakelig i *ewRestRequestExtChecklistSigning*::*readSigningData*
     * Reads info about the signing request.
     */
    static getV1ChecklistsExtsigningUsingSigninghash(signingHash) {
        return this._get('/v1/checklists/extsigning/{signingHash}', { signingHash }, null, null, PendingSignatureData.classOf(PendingSignatureData, new PendingSignatureData()), [], 'json');
    }
    /**
     * Create signature
     * Implementert hovedsakelig i *ewRestRequestExtChecklistSigning*::*createSignature*
     */
    static postV1ChecklistsExtsigningSignUsingSigninghash(signingHash, __DATA) {
        return this._post('/v1/checklists/extsigning/{signingHash}/sign', { signingHash }, null, this._formData(__DATA), SigningEntryData.classOf(SigningEntryData, new SigningEntryData()), [], 'json');
    }
}
