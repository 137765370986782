var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { html } from 'lit';
import { ChecklistSigningAPI } from 'signing-api/cerum/signing/checklist_signing/ChecklistSigningAPI';
import { SigningPortalStep } from '../interfaces/SigningPortalStep';
import { isErrorHTTP, isSuccessHTTP } from 'signing-api/APIRestClient';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { SigningStatus } from '../interfaces/SigningStatus';
import { SigningCanvasController } from './SigningCanvasController';
import { CerumConfirm } from 'cerumx-shared/CerumConfirm';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { SigningCompletionState } from '../interfaces/SigningCompletionState';
export class SigningController {
    constructor(host) {
        this.currentStep = SigningPortalStep.LOADING;
        this.signingCompleted = {
            complete: false,
            type: undefined,
        };
        this.stepRenderers = {};
        this.signingHash = '';
        this.isLoading = false;
        (this.host = host).addController(this);
    }
    getStepRenderers() {
        return __awaiter(this, void 0, void 0, function* () {
            const informationData = yield this._getSigningData();
            const signingConfirmationData = this._getSigningConfirmationData();
            const signingPortalStepInformation = {
                render: () => html `${this.host.renderSigningInformation()}`,
                footer: () => this.host.renderSigningInformationFooter(this.signingHash, Boolean(informationData === null || informationData === void 0 ? void 0 : informationData.signing)),
                data: informationData,
            };
            const signingPortalStepSigning = {
                render: () => this.host.renderSigning(),
                footer: () => this.host.renderSigningFooter(),
            };
            const signingPortalStepSigningConfirmation = {
                data: signingConfirmationData,
                render: () => this.host.renderSigningConfirmation(signingConfirmationData),
                footer: () => this.host.renderSigningConfirmationFooter(),
            };
            this.stepRenderers = {
                [SigningPortalStep.INFORMATION]: signingPortalStepInformation,
                [SigningPortalStep.SIGNING]: signingPortalStepSigning,
                [SigningPortalStep.SIGNINGCONFIRMATION]: signingPortalStepSigningConfirmation,
                [SigningPortalStep.LOADING]: { render: () => this.host.renderSigningLoading() },
                [SigningPortalStep.ERROR]: { render: () => this.host.renderError() },
            };
            this.host.requestUpdate();
        });
    }
    setCurrentStep(currentStep) {
        return __awaiter(this, void 0, void 0, function* () {
            this.currentStep = currentStep;
            this.host.requestUpdate();
        });
    }
    setCanvas(canvas) {
        this.canvas = canvas;
        this.signingCanvasController = new SigningCanvasController(this.host, canvas);
    }
    _getSigningData() {
        return __awaiter(this, void 0, void 0, function* () {
            const signingHash = new URLSearchParams(window.location.search).get('hash');
            if (signingHash) {
                this.signingHash = signingHash;
                return this._loadSigningData(signingHash);
            }
        });
    }
    _getSigningConfirmationData() {
        return { signatureData: this.signatureData, signingCompleted: this.signingCompleted };
    }
    _loadSigningData(signingHash) {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            const loadSigningInformationResult = yield ChecklistSigningAPI.getV1ChecklistsExtsigningUsingSigninghash(signingHash);
            if (isSuccessHTTP(loadSigningInformationResult)) {
                this.currentStep = SigningPortalStep.INFORMATION;
                this.signatureData = loadSigningInformationResult;
            }
            else if (isErrorHTTP(loadSigningInformationResult)) {
                this.currentStep = SigningPortalStep.ERROR;
            }
            this.isLoading = false;
            return this.signatureData;
        });
    }
    confirmSignature(isSignatureInvalid) {
        if (isSignatureInvalid) {
            return;
        }
        OverlayWindow.asyncInject({
            title: '',
            componentTagName: CerumConfirm.is,
            attributes: { text: 'Er signaturen tilstrekkelig utfylt?' },
            size: 'small',
            onClose: (confirm) => {
                if (confirm) {
                    this.currentStep = SigningPortalStep.SIGNINGCONFIRMATION;
                    this._prepareSignature();
                }
            },
        });
    }
    _prepareSignature() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const canvas = (_a = this.signingCanvasController) === null || _a === void 0 ? void 0 : _a.canvas;
            if (!canvas) {
                return;
            }
            canvas.toBlob((imageBlob) => {
                this._sendSignature(imageBlob);
            });
        });
    }
    _sendSignature(imageBlob) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!imageBlob || !this.signingHash) {
                return;
            }
            const sendSignatureResult = yield ChecklistSigningAPI.postV1ChecklistsExtsigningSignUsingSigninghash(this.signingHash, {
                signature_mime_type: imageBlob.type,
                signature_picture: imageBlob,
            });
            let status = undefined;
            if (isErrorHTTP(sendSignatureResult)) {
                status = SigningStatus.ERROR;
                SnackBar.pushError(sendSignatureResult.error);
            }
            else {
                status = SigningStatus.SUCCESS;
            }
            this.signingCompleted = { complete: true, type: status };
            this.host.requestUpdate();
        });
    }
    getSigningCompletionState(signingConfirmationData) {
        var _a;
        if ((_a = this.signatureData) === null || _a === void 0 ? void 0 : _a.signing) {
            return SigningCompletionState.ALREADYSIGNED;
        }
        else if (!signingConfirmationData.signingCompleted ||
            this.signingCompleted.type === SigningStatus.ERROR) {
            return SigningCompletionState.FAILED;
        }
        else if (this.signingCompleted.type === SigningStatus.SUCCESS) {
            return SigningCompletionState.COMPLETED;
        }
        return SigningCompletionState.UNKNOWN;
    }
    hostConnected() {
        this.getStepRenderers();
    }
    hostUpdated() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.currentStep === SigningPortalStep.SIGNING && !this.signingCanvasController) {
                const canvas = yield this.host.getCanvas();
                if (canvas) {
                    this.setCanvas(canvas);
                }
            }
        });
    }
}
