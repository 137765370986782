import { Router } from '@vaadin/router';
import { SigningPageIndex } from '../pages/SigningPageIndex';
import { StandaloneServices } from 'standalone-api/StandaloneServices';
export const router = new Router();
router.setRoutes([
    {
        path: '/',
        component: SigningPageIndex.is,
    },
]);
// This is just bogus to satisify pipeline while we're developing
StandaloneServices.instance();
